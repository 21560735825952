import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";

const App = () => {
    return (
        <section className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />}/>
                    <Route path="/home" element={<HomePage />}/>
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </BrowserRouter>
        </section>
    )
}

export default App;