import { useState } from "react";
import { changePass, signIn } from "./authService";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [changePassword, setChangePassword] = useState(false);

    const navigate = useNavigate();
    const handleSignIn = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            const session = await signIn(email, password);
            if (typeof session === "string") {
                setChangePassword(true);
            } else {
                console.log("Sign in successful", session);
                if (session && typeof session.AccessToken !== "undefined") {
                    sessionStorage.setItem("accessToken", session.AccessToken);
                    if (sessionStorage.getItem("accessToken")) {
                        navigate("/admin");
                    } else {
                        console.error("Session token was not set properly.");
                    }
                } else {
                    console.error("SignIn session or AccessToken is undefined.");
                }
            }
        } catch (error) {
            alert(`Sign in failed: ${error}`);
        }
    };

    const handleChangePassword = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            const changePassResp = await changePass(password, newPassword);
            if (changePassResp) {
                console.log("Change password request was successful.");
                setChangePassword(false);
            } else {
                console.error("Change password request failed.");
            }
        } catch (error) {
            alert(`Change password request failed: ${error}`);
        }
    }

    return (
        <Container className="loginForm">
            <NavBar />
            <section className="App">
                <Container>
                    <h1>Welcome</h1>
                    <h4>
                        Sign in to your account to access the Admin page
                    </h4>
                </Container>
                {changePassword ? (
                    <Container>
                        <h4>Change Password</h4>
                        <Form onSubmit={handleChangePassword}>
                            <Form.Group as={Row} className="mb-3" controlId="login.Email">
                                <Form.Label>Username or Email</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="email"
                                        onChange={((e) => setEmail(e.target.value))}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="login.OldPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="login.NewPassword">
                                <Form.Label>New Password</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Container>
                ) : (
                    <Container>
                        <Form onSubmit={handleSignIn}>
                            <Form.Group as={Row} className="mb-3" controlId="login.Email">
                                <Form.Label>Username or Email</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="email"
                                        onChange={((e) => setEmail(e.target.value))}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="login.Password">
                                <Form.Label>Password</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Container>
                )}
            </section>
        </Container>
    );
};

export default LoginPage;