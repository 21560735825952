import { Container, Nav } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";

const NavBar = () => {
    const isAuthenticated = () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return !!accessToken;
    };

    const navigate = useNavigate();

    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand>
                    <Nav.Link href="" onClick={() => navigate("/home")}>
                        Trading Bot Tracker
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="" onClick={() => navigate("/home")}>Home</Nav.Link>
                        {isAuthenticated() ? (
                            <Nav.Link href="" onClick={() => navigate("/admin")}>Admin</Nav.Link>
                        ) : (<Nav.Link href="" onClick={() => navigate("/login")}>Login</Nav.Link>)}
                        <Nav.Link href="https://trader.tradovate.com/welcome" target="_blank">Tradovate</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;