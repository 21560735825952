// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import {
    AuthFlowType,
    ChangePasswordCommand,
    ChangePasswordCommandInput,
    ChangePasswordCommandOutput,
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import config from "./config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
    region: config.region,
});

export const signIn = async (username: string, password: string) => {
    const params = {
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        ClientId: config.clientId,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
    };
    try {
        const command = new InitiateAuthCommand(params);
        const response = await cognitoClient.send(command);
        console.log("Response: ", response);
        if (response.ChallengeName) {
            response.Session && sessionStorage.setItem("sessionToken", response.Session);
            response.AuthenticationResult && sessionStorage.setItem(
                "accessToken",
                response.AuthenticationResult.AccessToken || "",
            );
            return response.ChallengeName;
        } else if (response.AuthenticationResult) {
            sessionStorage.setItem("idToken", response.AuthenticationResult.IdToken || "");
            sessionStorage.setItem(
                "accessToken",
                response.AuthenticationResult.AccessToken || "",
            );
            sessionStorage.setItem(
                "refreshToken",
                response.AuthenticationResult.RefreshToken || "",
            );
            return response.AuthenticationResult;
        }
    } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
    }
};

export const changePass = async (oldPassword: string, newPassword: string) => {
    const params: ChangePasswordCommandInput = {
        PreviousPassword: oldPassword,
        ProposedPassword: newPassword,
        AccessToken: sessionStorage.getItem("accessToken") ?? ""
    };
    try {
        const command = new ChangePasswordCommand(params);
        const response: ChangePasswordCommandOutput = await cognitoClient.send(command);
        return response.$metadata.httpStatusCode === 200;
    } catch (error) {
        console.log("Error changing password: ", error);
        throw error;
    }
}
